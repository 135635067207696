export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENTION: without the unique return code, your package cannot be identified."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account number starts with %s (example: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be sure to include your unique complaint number with the product in the package. Your unique complaint number is"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damaged product"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greetings "])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information is not required"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reason"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on our website, and not with the delivery service / courier"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product quality"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product replacement"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product return procedure"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product return process"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for the complaint"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund form"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returning the product is quick and easy"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returning the product is quick and easy."])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STEP"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select type of complaint"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the package to"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the package to the following address:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong, try again later or contact our support"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit the form"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account number starts with %s (example: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The return procedure has just been forwarded to your e-mail address:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together with the product to be returned, enter your unique return code in the package.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of payment"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong product"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong quantity"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can print out already completed information to help return the product"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already submitted a return request. Be patient"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received a PDF file for return procedure in the attachment."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your unique return number is"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complaint number"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instructions"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["post code"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reciever"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sender"])}
}